






































import { InputSetups } from '@/mixins/input-setups'
import WorkTimeCondition from './WorkTimeCondition'

import { UseFields } from 'piramis-base-components/src/components/Pi'

import { Component, Mixins, VModel } from 'vue-property-decorator'
import TimezoneSelect from "@/components/TimezoneSelect.vue";

@Component({
  components: {
    TimezoneSelect
  }
})
export default class WorkTimeConditionView extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @VModel() model!: WorkTimeCondition

}
